import { AuthGuard } from "@/components/auth/auth-guard"
import { Toaster } from "@/components/ui/toaster"
import "@/styles/globals.css"
import { api } from "@/utils/api"
import { supabase } from "@/utils/supabase"
import { HighlightInit } from "@highlight-run/next/client"
import { SessionContextProvider } from "@supabase/auth-helpers-react"
import { Analytics } from "@vercel/analytics/next"
import { SpeedInsights } from "@vercel/speed-insights/next"
import { type AppType } from "next/app"
import { Inter } from "next/font/google"
import Head from "next/head"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "700"],
})

const isProduction = process.env.NODE_ENV === "production"
const readyToTrackAnalyticsInProd =
  isProduction && typeof window !== "undefined"

if (readyToTrackAnalyticsInProd) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: "/ingest",
    ui_host: "https://us.posthog.com",
  })
}

const title =
  process.env.NODE_ENV === "development" ? "EpicScript (dev)" : "EpicScript"

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <main className={inter.className}>
        {isProduction && (
          <HighlightInit
            projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
            serviceName="epicscri.pt-client"
            tracingOrigins
            networkRecording={{
              enabled: true,
              recordHeadersAndBody: true,
              urlBlocklist: [],
            }}
          />
        )}

        <PostHogProvider>
          <SessionContextProvider
            initialSession={pageProps.initialSession}
            supabaseClient={supabase}
          >
            <AuthGuard>
              <Component {...pageProps} />
              <Analytics />
              <SpeedInsights />
            </AuthGuard>
          </SessionContextProvider>
        </PostHogProvider>
      </main>
      <Toaster />
    </>
  )
}

export default api.withTRPC(MyApp)
