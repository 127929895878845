import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { useRouter } from "next/router"
import { type ReactNode, useEffect } from "react"

const PROTECTED_ROUTES = ["/scripts", "/editor"]

type AuthGuardProps = {
  children: ReactNode
}

export function AuthGuard({ children }: AuthGuardProps) {
  const supabase = useSupabaseClient()
  const router = useRouter()

  useEffect(() => {
    const checkAuth = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession()

      const isProtectedRoute = PROTECTED_ROUTES.some((route) =>
        router.pathname.startsWith(route),
      )

      if (!session && isProtectedRoute) {
        void router.push("/")
      } else if (session && router.pathname === "/") {
        void router.push("/scripts")
      }
    }

    // Initial check
    void checkAuth()

    // Listen to auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      // Immediately check and redirect if needed
      if (
        !session &&
        PROTECTED_ROUTES.some((route) => router.pathname.startsWith(route))
      ) {
        void router.push("/")
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [supabase, router])

  return <>{children}</>
}
